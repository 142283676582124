import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "react-quill/dist/quill.snow.css";

import Stroke from "../../assets/background/Struk.svg";
import { getTermsAndCondition } from "../../services/API";

const PrivacySection = () => {
  const navigate = useNavigate();
  const { data: termsAndConditionData } = useQuery({
    queryFn: () => getTermsAndCondition(1),
    queryKey: ["terms-condition"],
  });

  const parser = new DOMParser();
  const html =
    termsAndConditionData && termsAndConditionData !== undefined
      ? parser.parseFromString(termsAndConditionData.title, "text/html")
      : "";

  return (
    <div>
      <img src={Stroke} alt="" className="hidden lg:block w-full" />

      <div className="flex mx-0 md:mx-32 mt-0 md:mt-10">
        <div className="w-[0px] md:w-[30%]  flex-col hidden md:flex">
          <p className="font-bold">OLIN</p>
          <div className="text-blue-500 font-bold ml-3">
            <p
              className="my-3 hover:cursor-pointer"
              onClick={() => navigate("/FAQ")}
            >
              <a>Pertanyaan Umum</a>
            </p>
            <p className="my-3" id="2" onClick={() => navigate("/FAQ")}>
              <a>Cara Berlangganan</a>
            </p>
            <p className="my-3" id="3" onClick={() => navigate("/FAQ")}>
              <a>Cara Menggunakan</a>
            </p>
          </div>
          <p
            className="my-3 text-blue-500 font-bold hover:cursor-pointer"
            onClick={() => navigate("/kebijakan-privasi")}
          >
            <a>Kebijakan Privasi</a>
          </p>
          <p
            className="my-3 text-blue-500 font-bold hover:cursor-pointer"
            onClick={() => navigate("/syarat-ketentuan")}
          >
            <a>Syarat & Ketentuan</a>
          </p>
        </div>

        <div className="w-full md:w-[70%]  flex flex-col border-l-0 md:border-l-4">
          <div className="ml-0 px-8 md:px-0 md:ml-7 ql-editor">
            {termsAndConditionData && termsAndConditionData !== undefined ? (
              <>
                {/* <p className="font-bold text-blue-500" id="four">
                  Kebijakan Privasi
                </p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: termsAndConditionData.title,
                  }}
                  className="mt-5 font-bold text-blue-500"
                  id="four"
                ></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: termsAndConditionData.content,
                  }}
                  className="mt-5"
                ></div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacySection;
