import TopBanner from "../common/TopBanner";
import FaqContent from "./FaqContent";

import TopBannerImgMobile from "../../assets/background/faqmobile.png";
import TopBannerImg from "../../assets/background/faq.png";

const FAQ = () => {
  return (
    <>
      <TopBanner
        desktopImg={TopBannerImg}
        mobileImg={TopBannerImgMobile}
        hasButton={true}
        url="https://api.whatsapp.com/send?phone=6281113307870"
        title="Ada yang bisa kami bantu?"
        subtitle={<></>}
      />
      <FaqContent />
    </>
  );
};

export default FAQ;
