import React, { useEffect } from "react";
import Stroke from "../../assets/background/Struk.svg";
import Aos from "aos";
import "aos/dist/aos.css";

const Bg =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/130790f1d1f68fe30adef7f71cbc71de375906264e60698684096fe3c519f852.png";

const ContactBanner = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="w-full mx-auto bg-[#24337A]">
      <div className="bg-[#24337A] px-5 md:px-24">
        <div className="relative md:hidden">
          <img src={Bg} alt="" className="p-2 rounded-full" />
          <div className="text-white flex flex-col">
            <p className="font-bold text-[30px] md:text-[50px] text-white pt-[20px]">
              Ingin mengetahui lebih lanjut?
            </p>
            <p>
              Kami siap menjawab dan memandu Anda meningkatkan usaha apotek Anda
              dengan OLIN. Hubungi kami sekarang!
            </p>
            <button className="text-blue-800 font-bold bg-white py-[12px] mt-[50px] rounded-[30px] px-16 hover:bg-blue-500 hover:text-white">
              <a href="https://api.whatsapp.com/send?phone=6281113307870">
                Hubungi Kami
              </a>
            </button>
          </div>
        </div>
        <div className="hidden md:flex w-full pt-10 flex flex-row items-center justify-around">
          <div className="text-white">
            <p className="font-bold text-[50px]">
              Ingin mengetahui <br /> lebih lanjut?
            </p>
            <p className="mt-5">
              Kami siap menjawab dan memandu Anda meningkatkan usaha apotek{" "}
              <br /> Anda dengan OLIN. Hubungi kami sekarang!
            </p>
            <button className="text-blue-800 font-bold bg-white py-[12px] mt-20 rounded-[30px] px-16 hover:text-white hover:bg-blue-700">
              <a href="https://api.whatsapp.com/send?phone=6281113307870">
                Hubungi Kami
              </a>
            </button>
          </div>
          <div data-aos="zoom-in">
            <img src={Bg} alt="" className="rounded-full" />
          </div>
        </div>
      </div>
      <img src={Stroke} alt="" className="mt-5 w-full" />
    </div>
  );
};

export default ContactBanner;
