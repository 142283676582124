import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getTermsAndCondition } from "../../services/API";

const FaqContent = () => {
  const navigate = useNavigate();
  const { data: termsAndConditionData } = useQuery({
    queryFn: () => getTermsAndCondition(1),
    queryKey: ["terms-condition"],
  });

  const parser = new DOMParser();

  return (
    <div>
      <div className="flex mx-0 md:mx-32 mt-0 md:mt-10">
        <div className="w-[0px] md:w-[30%]  flex-col hidden md:flex">
          <p className="font-bold">OLIN</p>
          <div className="text-blue-500 font-bold ml-3">
            <p className="my-3">
              <a href="#one">Pertanyaan Umum</a>
            </p>
            <p className="my-3" id="2">
              <a href="#two">Cara Berlangganan</a>
            </p>
            <p className="my-3" id="3">
              <a href="#three">Cara Menggunakan</a>
            </p>
          </div>
          <p
            className="my-3 text-blue-500 font-bold hover:cursor-pointer"
            onClick={() => navigate("/kebijakan-privasi")}
          >
            <a>Kebijakan Privasi</a>
          </p>
          <p
            className="my-3 text-blue-500 font-bold hover:cursor-pointer"
            onClick={() => navigate("/syarat-ketentuan")}
          >
            <a>Syarat & Ketentuan</a>
          </p>
        </div>

        <div className="w-full md:w-[70%]  flex flex-col border-l-0 md:border-l-4">
          <div className="ml-0 px-8 md:px-0 md:ml-7 ">
            <p className="font-bold text-blue-500" id="one">
              Pertanyaan Umum
            </p>
            <div className="my-5">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white h-12 w-full flex items-center">
                  <p className="text-lg font-semibold pr-10 text-black">
                    Apa itu OLIN?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <p>
                    OLIN adalah perusahaan teknologi yang menyediakan berbagai
                    solusi untuk kebutuhan operasional bisnis apotek.
                  </p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">Apa itu OLIN?</p>
                <p>
                  OLIN adalah perusahaan teknologi yang menyediakan berbagai
                  solusi untuk kebutuhan operasional bisnis apotek.{" "}
                </p>
              </div>
            </div>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white h-12 w-full flex items-center">
                  <p className="text-lg font-semibold pr-10 text-black">
                    Apa itu Aplikasi OLIN?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <p>
                    Aplikasi OLIN adalah sebuah sistem manajemen apotek yang
                    memiliki fitur fitur untuk membantu kegiatan operasional
                    apotek
                  </p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">Apa itu Aplikasi OLIN?</p>
                <p>
                  Aplikasi OLIN adalah sebuah sistem manajemen apotek yang
                  memiliki fitur fitur untuk membantu kegiatan operasional
                  apotek
                </p>
              </div>
            </div>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-10 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white h-12 my-5 w-full flex items-center">
                  <p className="text-lg font-semibold pr-10 text-black">
                    Tipe bisnis seperti apa yang cocok menggunakan aplikasi
                    OLIN?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <p>
                    Sistem OLIN didedikasikan untuk bisnis penjualan produk
                    farmasi seperti apotek, & toko obat.
                  </p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">
                  Tipe bisnis seperti apa yang cocok menggunakan aplikasi OLIN?
                </p>
                <p>
                  Sistem OLIN didedikasikan untuk bisnis penjualan produk
                  farmasi seperti apotek, & toko obat.
                </p>
              </div>
            </div>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white my-5 h-12 w-full flex items-center">
                  <p className="text-lg font-semibold pr-10 text-black">
                    Apakah Apotek Online saya dapat dikoneksi dengan aplikasi
                    OLIN?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <p>
                    Aplikasi OLIN dapat memfasilitasi integrasi dengan apotek
                    online berbasis telemedicine dari platform
                    {/* <span>x, x, & x</span> */}
                    lainnya
                  </p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">
                  Apakah Apotek Online saya dapat dikoneksi dengan aplikasi
                  OLIN?
                </p>
                <p>
                  Aplikasi OLIN dapat memfasilitasi integrasi dengan apotek
                  online berbasis telemedicine dari platform lainnya
                  {/* <span>x, x, & x</span> */}{" "}
                </p>
              </div>
            </div>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white my-8 h-12 w-full flex items-center">
                  <p className="text-lg font-semibold pr-10 text-black">
                    Apakah Saya dapat mencoba untuk menggunakan aplikasi OLIN?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <p>
                    Daftarkan diri anda pada link ini & tim kami akan mengkontak
                    anda untuk mencoba aplikasi OLIN sampai 14 hari.
                  </p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">
                  Apakah Saya dapat mencoba untuk menggunakan aplikasi OLIN?
                </p>
                <p>
                  Daftarkan diri anda pada link ini & tim kami akan mengkontak
                  anda untuk mencoba aplikasi OLIN sampai 14 hari.
                </p>
              </div>
            </div>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white my-8 h-12 w-full flex items-center">
                  <p className="text-lg font-semibold pr-10 text-black">
                    Perangkat apa saja yang saya perlukan untuk menggunakan
                    aplikasi OLIN?
                  </p>
                </div>
                <div className="absolute top-3 right-1 text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <p>
                    Anda membutuhkan device android dengan Operating System (OS)
                    minimum Android 8 (Oreo)
                  </p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">
                  Perangkat apa saja yang saya perlukan untuk menggunakan
                  aplikasi OLIN?
                </p>
                <p>
                  Anda membutuhkan device android dengan Operating System (OS)
                  minimum Android 8 (Oreo)
                </p>
              </div>
            </div>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white h-12 my-5 w-full flex items-center">
                  <p className="text-lg font-semibold pr-10 text-black">
                    Apakah OLIN juga menyediakan paket perangkat lengkap?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <p>
                    OLIN menjual beragam perangkat pelengkap sistem apotek mulai
                    dari tablet pc, monitor, laci kasir, printer, &
                    scanner.Silahkan hubungi tim kami di{" "}
                    <a
                      href="https://api.whatsapp.com/send?phone=6281113307870"
                      className="text-blue-500 mx-2"
                    >
                      Whatsapp
                    </a>{" "}
                    untuk melihat katalog produk.
                  </p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">
                  Apakah OLIN juga menyediakan paket perangkat lengkap?
                </p>
                <p>
                  OLIN menjual beragam perangkat pelengkap sistem apotek mulai
                  dari tablet pc, monitor, laci kasir, printer, &
                  scanner.Silahkan hubungi tim kami di{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=6281113307870"
                    className="text-blue-500 "
                  >
                    Whatsapp
                  </a>{" "}
                  untuk melihat katalog produk.
                </p>
              </div>
            </div>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-6 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white my-12 h-12 w-full flex items-center">
                  <p className="text-lg font-semibold pr-10 text-black">
                    Mengapa saya harus memilih Aplikasi OLIN dari sistem
                    Manajemen Apotek tradisional?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-44">
                  <p>
                    Olin menyediakan fitur-fitur, dashboard, serta laporan
                    aktifitas bisnis dengan mudah. Aplikasi Olin juga dapat
                    berintegrasi dengan semua proses jadi Anda dapat memonitor
                    seluruh operasional apotek dari satu layar
                  </p>
                </div>
                <hr className="border-black border-1 my-5" />
              </div>
              <div className="hidden md:block ">
                <p className="font-bold">
                  Mengapa saya harus memilih Aplikasi OLIN dari sistem Manajemen
                  Apotek tradisional?
                </p>
                <p>
                  Olin menyediakan fitur-fitur, dashboard, serta laporan
                  aktifitas bisnis dengan mudah. Aplikasi Olin juga dapat
                  berintegrasi dengan semua proses jadi Anda dapat memonitor
                  seluruh operasional apotek dari satu layar
                </p>
              </div>
            </div>
            <p className="font-bold text-blue-500" id="two">
              Cara Berlangganan
            </p>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white h-12 w-full my-5 flex items-center">
                  <p className="text-lg font-semibold pr-10 text-black">
                    Bagaimana cara berlangganan aplikasi OLIN?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-64">
                  <p>
                    <ol className="list-decimal gap-2">
                      <li className="mt-3">
                        Daftarkan diri Anda melalui aplikasi OLIN atau mulai
                        percakapan dengan tim kami di
                        <a href="https://api.whatsapp.com/send?phone=6281113307870">
                          WA
                        </a>
                      </li>
                      <li className="my-3">
                        Tim kami akan menghubungi & menjawab semua kebutuhan dan
                        pertanyaan Anda
                      </li>
                      <li>
                        Anda juga dapat membeli perangkat disini [ hyperlink
                        perangkat]
                      </li>
                    </ol>
                  </p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">
                  Bagaimana cara berlangganan aplikasi OLIN?
                </p>
                <ol className="list-decimal gap-2">
                  <li className="mt-3">
                    Daftarkan diri Anda melalui aplikasi OLIN atau mulai
                    percakapan dengan tim kami di{" "}
                    <a
                      href="https://api.whatsapp.com/send?phone=6281113307870"
                      className="text-blue-500"
                    >
                      Whatsapp
                    </a>
                  </li>
                  <li className="my-3">
                    Tim kami akan menghubungi & menjawab semua kebutuhan dan
                    pertanyaan Anda
                  </li>
                  <li>
                    Anda juga dapat membeli perangkat melalui{" "}
                    <a
                      href="https://api.whatsapp.com/send?phone=6281113307870"
                      className="text-blue-500"
                    >
                      tim kami
                    </a>
                    {/* <span>disini [ hyperlink perangkat]</span> */}
                  </li>
                </ol>
              </div>
            </div>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white h-12 w-full flex items-center">
                  <p className="text-lg font-semibold text-black">
                    Berapa biaya berlangganan aplikasi OLIN?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <p>
                    Dapatkan harga terbaik sistem OLIN senilai IDR 300 k/ bulan
                    untuk berlangganan
                  </p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">
                  Berapa biaya berlangganan aplikasi OLIN?
                </p>
                <p>
                  Dapatkan harga terbaik sistem OLIN senilai IDR 300 k/ bulan
                  untuk berlangganan
                </p>
              </div>
            </div>
            <p className="font-bold text-blue-500" id="three">
              Cara Menggunakan
            </p>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white h-12 my-3 w-full flex items-center">
                  <p className="text-lg font-semibold  pr-10  text-black">
                    Bagaimana cara menginstal aplikasi OLIN dalam perangkat?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <ol className="list-decimal gap-2">
                    <li className="mt-3">
                      Install aplikasi OLIN dari Google Play pada tablet Android
                      Anda.
                    </li>
                    <li className="my-3">
                      Masukan kredential akun Anda kedalam menu login
                    </li>
                    <li>
                      Pastikan akun Anda sudah terverifikasi oleh tim kami &
                      sudah dapat digunakan
                    </li>
                  </ol>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">
                  Bagaimana cara menginstal aplikasi OLIN dalam perangkat?
                </p>
                <ol className="list-decimal gap-2">
                  <li className="mt-3">
                    Install aplikasi OLIN dari Google Play pada tablet Android
                    Anda.
                  </li>
                  <li className="my-3">
                    Masukan kredential akun Anda kedalam menu login
                  </li>
                  <li>
                    Pastikan akun Anda sudah terverifikasi oleh tim kami & sudah
                    dapat digunakan
                  </li>
                </ol>
              </div>
            </div>
            <div className="my-7">
              <div className="relative w-full overflow-hidden my-5 md:hidden">
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className="bg-white h-12 my-5 w-full flex items-center">
                  <p className="text-lg pr-10  font-semibold text-black">
                    Bagaimana cara menggunakan fitur dalam aplikasi OLIN?
                  </p>
                </div>
                <div className="absolute top-3 right-[-3px] text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <p className="mt-3">Baca panduan fitur kami disini</p>
                </div>
                <hr className="border-black border-1 my-4" />
              </div>
              <div className="hidden md:block">
                <p className="font-bold">
                  Bagaimana cara menggunakan fitur dalam aplikasi OLIN?
                </p>
                <p>Baca panduan fitur kami disini</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqContent;
