import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Olin from "../../assets/icons/olinfull.png";
import { NavLink, Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const activeLink = "border-b-4 pb-1 border-blue-700";
  const normalLink = "";

  const changePage = () => {
    const url = `${process.env.REACT_APP_LINK_URL}`;
    window.location.href = url;
  };

  return (
    <div className="sticky top-0 bg-white z-20 shadow-lg ">
      <div className="flex flex-row justify-between items-center h-[80px] px-0 md:px-18 lg:px-32  mx-auto px-4 text-black bg-transparent z-50">
        <NavLink to="/">
          <div className="w-[70px]">
            <img src={Olin} alt="" className="w-full" />
          </div>
        </NavLink>
        <ul className="hidden md:flex items-center justify-end gap-2 font-bold text-sm text-md w-full">
          <li className="p-5">
            <NavLink
              to="/product"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Produk
            </NavLink>
          </li>
          <li className="p-5">
            <NavLink
              to="/perangkat"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Perangkat
            </NavLink>
          </li>
          <li className="p-5">
            <NavLink
              to="/tentang"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Tentang
            </NavLink>
          </li>
          <li className="p-5">
            <NavLink
              to="/blog"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Blog
            </NavLink>
          </li>

          <li className="p-5 cursor-pointer" onClick={changePage}>
            Masuk
          </li>
          <button
            className="bg-[#24337A] text-white rounded-2xl px-[20px] w-max py-[5px] hover:bg-slate-100 hover:text-black"
            onClick={() => navigate("/demo")}
          >
            Jadwalkan Demo
          </button>
        </ul>

        <div onClick={handleNav} className="block md:hidden z-50">
          {nav ? null : <AiOutlineMenu size={20} />}
        </div>

        <ul
          className={
            nav
              ? "fixed left-0 top-0 w-[100%] z-20 h-full border-r border-r-gray-900 text-black bg-white ease-in-out duration-500"
              : "ease-in-out duration-500 fixed left-[-100%]"
          }
        >
          <div className="flex flex-row justify-between items-center">
            <img src={Olin} alt="" className="w-[70px] my-5 ml-2" />
            <div onClick={handleNav} className="block md:hidden p-3">
              {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={20} />}
            </div>
          </div>
          <li className="p-4 border-b border-gray-600 mt-20">
            <span>
              <Link to="/">Home</Link>
            </span>
          </li>
          <Link to="/product">
            <li className="p-4 border-b border-gray-600">Product</li>
          </Link>
          <Link to="/blog">
            <li className="p-4 border-b border-gray-600">Blog</li>
          </Link>
          <Link to="/tentang">
            <li className="p-4 border-b border-gray-600">Tentang</li>
          </Link>
          <Link to="/perangkat">
            <li className="p-4 border-b border-gray-600">Perangkat</li>
          </Link>
          <Link to="/demo">
            <li className="p-4 border-b border-gray-600">Jadwalkan Demo</li>
          </Link>
          <li className="p-4 hover:cursor-pointer" onClick={changePage}>
            Masuk
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
