import DemoBanner from "./DemoBanner";
import Stroke from "../../assets/background/Struk.svg";
import MapImg from "../../assets/background/PharmacyMap.svg";
import CloudGear from "../../assets/icons/cloudGear.svg";
import Pbf from "../../assets/icons/pbfSupport.svg";
import AfterSales from "../../assets/icons/afterSales.svg";
import BubbleBackground from "./BubbleBackground";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SontaniImg from "../../assets/pharmacyImages/Sontani.jpeg";
import KopoImg from "../../assets/pharmacyImages/Kopo.jpeg";
import KliniekenImg from "../../assets/pharmacyImages/Klinieken.jpeg";
import KalilicinImg from "../../assets/pharmacyImages/Kalilicin.jpeg";
import RafflessiaImg from "../../assets/pharmacyImages/Rafflesia.jpeg";
import SahabatImg from "../../assets/pharmacyImages/SahabatSehat.jpeg";

const testimony = [
  {
    pharmacy: "Apotek Sontani",
    region: "Bandung",
    testimony:
      "Setelah menggunakan OLIN, kita jadi lebih aware pada stok obat yang mau kadaluwarsa",
    img: SontaniImg,
  },
  {
    pharmacy: "Apotek Kopo",
    region: "Bandung",
    testimony: "Dengan OLIN, kita lebih mudah input stok dan menelusuri barang",
    img: KopoImg,
  },
  {
    pharmacy: "Apotek Klinieken",
    region: "Bandung",
    testimony: "OLIN membantu banget dalam ngatur transaksi dan stok obat",
    img: KliniekenImg,
  },
  {
    pharmacy: "Apotek Kalilicin",
    region: "Bandung",
    testimony:
      "Fitur-fitur di OLIN lengkap dan mudah digunakan. Sangat membantu",
    img: KalilicinImg,
  },
  {
    pharmacy: "Apotek Rafflessia",
    region: "Bandung",
    testimony:
      "Aplikasi ini praktis banget buat nge-manage stok obat di apotek.",
    img: RafflessiaImg,
  },
  {
    pharmacy: "Apotek Sahabat Sehat",
    region: "Bekasi",
    testimony:
      "Selain aplikasinya mudah digunakan, OLIN juga ngasih support team yang siap bantu",
    img: SahabatImg,
  },
];

const Demo = () => {
  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <DemoBanner />
      <div className="pb-4 bg-white overflow-x-hidden">
        <div className="w-full mx-auto mb-8 p-4">
          <p
            className="font-bold p-12 md:p-24 px-0 md:px-40 text-xl md:text-3xl text-center"
            data-aos="zoom-in"
          >
            Dipercaya Lebih dari 100 Apotek di Seluruh Indonesia
          </p>
          <img
            alt=""
            data-aos="fade-up"
            src={MapImg}
            className="w-full max-h-[50vh] object-contain"
          />
        </div>
      </div>
      <img src={Stroke} className="w-full" alt="" />
      <div className="py-8 w-full relative z-10">
        <div className="bg-[#24337A] w-full h-full absolute top-0 -z-30"></div>
        <div className="absolute right-0 bottom-0 -z-20">
          <svg
            width="236"
            height="622"
            viewBox="0 0 236 622"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="411" cy="411" r="411" fill="#3145A5" />
            <circle cx="411" cy="411" r="345" fill="#4259C7" />
          </svg>
        </div>
        <BubbleBackground bubbleNum={15} />
        <p className="text-3xl text-white font-bold text-center mt-12 mb-12 px-12">
          Testimoni dari Pelanggan Kami
        </p>

        <div className="px-12 md:px-24 mb-24">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={24}
            breakpoints={{
              576: {
                slidesPerView: 1,
              },
              1080: {
                slidesPerView: 3,
              },
            }}
            // navigation
            pagination={{ clickable: true }}
            autoplay
          >
            {testimony.map((item, i) => {
              return (
                <SwiperSlide className="py-12 md:py-24 flex">
                  <div className="bg-white h-full rounded-md relative h-[30vh] md:h-[30vh] px-8 flex justify-center flex-col">
                    <div className="bg-white p-[4px] w-[64px] h-[64px] md:w-[72px] md:h-[72px] rounded-full absolute -top-[15%] md:-top-[18%] left-0 right-0 mx-auto bg-gradient-to-r from-[#F5ED13] from-2.32% via-[#7AC57A] via-37.09% via-[#00ACED] via-72.39% to-[#001489] to-103.56%">
                      <img
                        src={item.img}
                        className="w-full h-full rounded-full"
                      />
                    </div>
                    <p className="mb-8 text-left">{item.testimony}</p>
                    <p className="font-bold text-right">{item.pharmacy}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="mb-24 relative h-full" data-aos="fade-up">
          <p className="text-xl text-white text-center mb-4">Mengapa OLIN</p>
          <p className="text-2xl md:text-3xl text-white font-bold text-center">
            Digitalisasi apotek Anda
            <br /> dengan mudah dan aman
          </p>
        </div>
        <div className="block md:flex gap-4 px-12 justify-center items-center">
          <div
            className="w-full text-white text-center mb-12"
            data-aos="fade-right"
          >
            <img src={CloudGear} className="mx-auto mb-4" alt="" />
            <p className="font-bold mb-4 text-xl">Berbasis Cloud</p>
            <p>
              Gratis biaya implementasi, <br />
              pemeliharaan, dan update feature.
            </p>
          </div>
          <div
            className="w-full text-white text-center mb-12"
            data-aos="fade-right"
          >
            <img src={Pbf} className="mx-auto mb-4" alt="" />
            <p className="font-bold mb-4 text-xl">Didukung Oleh PBF</p>
            <p>
              Didukung langsung oleh PT. MPI <br />
              untuk pengadaan obat secara online
            </p>
          </div>{" "}
          <div
            className="w-full text-white text-center mb-12"
            data-aos="fade-right"
          >
            <img src={AfterSales} className="mx-auto mb-4" alt="" />
            <p className="font-bold mb-4 text-xl">Pelayanan After Sales</p>
            <p>
              Dukungan konsultasi, bantuan pelatihan <br />
              hingga purna jual.
            </p>
          </div>
        </div>
        <div className="flex justify-center my-8" data-aos="fade-up">
          <button
            onClick={scrollToTop}
            className="rounded-full p-3 bg-[#E3E721] cursor-pointer px-24 font-bold hover:bg-[#696a12] hover:text-white mb-4"
          >
            Jadwalkan Sekarang
          </button>
        </div>
      </div>
    </>
  );
};

export default Demo;
