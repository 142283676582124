import React from "react";
import Stroke from "../../assets/background/Struk.svg";
import Banner from "../../assets/background/bannerhome.png";
import BannerMobile from "../../assets/background/bannerhomemobile.png";
import Accordion from "../utils/Accordion";
import { Link } from "react-router-dom";

const JoinUsBanner = () => {
  return (
    <div>
      <div>
        <img src={Stroke} alt="" className="w-full" />
      </div>
      <div className="flex flex-col items-center">
        <div className="hidden md:grid items-center p-12 w-full">
          <div className="row-start-1 col-start-1">
            <img src={Banner} className="w-full" alt="" />
          </div>
          <div className="row-start-1 col-start-1 text-right px-8">
            <button className="transform duration-200 hover:scale-110 hover:bg-blue-900 p-2 font-bold max-[860px]:text-[10px] text-[14px] 2xl:text-[28px] text-white border-4 rounded-lg px-4">
              <Link to="/product">Berlangganan</Link>
            </button>
          </div>
        </div>

        <div className="relative block md:hidden w-full mt-2 px-2">
          <img src={BannerMobile} alt="" className="w-full" />
          <div className="relative">
            <button className="absolute bottom-6 rounded-xl right-[48px]  transform duration-200 hover:scale-110 p-1 px-3 font-bold text-[15px] text-white border-2">
              <Link to="/product">Berlangganan</Link>
            </button>
          </div>
        </div>

        <div className="font-bold text-[30px] md:text-[50px] mt-10 md:mt-20 ">
          <p>FAQ</p>
        </div>
        <div className="w-full px-0 md:px-[135px] mb-5 md:mb-10">
          <Accordion />
        </div>
      </div>
    </div>
  );
};

export default JoinUsBanner;
