import React, { useEffect, useState } from "react";
import Stroke from "../../assets/background/Struk.svg";
import DemoImg from "../../assets/background/demo.png";
import OlinMascot from "../../assets/background/olinCharaLogin.png";
import PromoTag from "../../assets/background/PromoTagDemo.svg";
import { BsClock } from "react-icons/bs";
import Aos from "aos";
import "aos/dist/aos.css";
import BubbleBackground from "./BubbleBackground";
import { useMutation } from "@tanstack/react-query";
import { setWebsiteLead } from "../../services/demoAPI";
import { addHours, differenceInSeconds, format, isBefore } from "date-fns";
import DemoDialog from "./DemoDialog";

const INIITAL_VALUE = {
  customer_name: "",
  pharmacy_name: "",
  whatsapp_number: "",
  customer_email: "",
  source: "website",
};

const DemoBanner = () => {
  const dialog = document.querySelector("dialog");
  const [countdownSeconds, setCountdownSeconds] = useState(0);

  const [reqBody, setReqBody] = useState(INIITAL_VALUE);
  const [errorMessage, setErrorMessage] = useState("");

  dialog?.addEventListener("click", function (event) {
    var rect = dialog.getBoundingClientRect();
    var isInDialog =
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width;
    if (!isInDialog) {
      dialog.close();
    }
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isValid = () => {
    var tempValid = true;

    //validation for whatsapp number still missing if prefix 62
    if (reqBody.customer_name === "") {
      setErrorMessage("Mohon isi nama lengkap");
      tempValid = false;
    }
    if (reqBody.pharmacy_name === "") {
      setErrorMessage("Mohon isi nama apotek");
      tempValid = false;
    }
    if (reqBody.whatsapp_number === "") {
      setErrorMessage("Mohon isi nomor whatsapp");
      tempValid = false;
    }
    if (
      reqBody.whatsapp_number !== "" &&
      ((reqBody.whatsapp_number.length < 10 && reqBody.whatsapp_number > 13) ||
        reqBody.whatsapp_number[0] !== "0")
    ) {
      setErrorMessage("Mohon isi nomor whatsapp yang aktif");
      tempValid = false;
    }
    if (reqBody.customer_email === "") {
      setErrorMessage("Mohon isi email anda");
      tempValid = false;
    }
    if (
      reqBody.customer_email !== "" &&
      !validateEmail(reqBody.customer_email)
    ) {
      setErrorMessage("Mohon masukan email yang valid");
      tempValid = false;
    }

    return tempValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      dialog.showModal();
      mutateLead(reqBody);
    }
  };

  const linkTimer = () => {
    const interval = setInterval(() => {
      setCountdownSeconds((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval);
          setCountdownSeconds(0);
        } else return prevCount - 1;
      });

      return () => clearInterval(interval);
    }, 1000);
  };

  const getTime = () => {
    const hour = Math.floor(countdownSeconds / 3600);
    const minutes = Math.floor((countdownSeconds - hour * 3600) / 60);
    const seconds = countdownSeconds - hour * 3600 - minutes * 60;

    return `${hour.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${minutes.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${seconds.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;
  };

  const { mutate: mutateLead, isPending } = useMutation({
    mutationFn: setWebsiteLead,
    onSuccess: () => {
      setReqBody(INIITAL_VALUE);
    },
    onError: (err) => {
      setErrorMessage("Nomor Whatsapp sudah pernah digunakan");
    },
  });

  useEffect(() => {
    setErrorMessage("");
  }, [reqBody]);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const cacheVal = localStorage.getItem("olinTimerExpired");

    if (cacheVal && isBefore(new Date(), new Date(cacheVal))) {
      setCountdownSeconds(differenceInSeconds(new Date(cacheVal), new Date()));
    } else {
      setCountdownSeconds(48 * 60 * 60);
      localStorage.setItem(
        "olinTimerExpired",
        format(addHours(new Date(), 48), "yyyy-MM-dd HH:mm:ss")
      );
    }
    linkTimer();
  }, []);

  return (
    <>
      <div className="w-full relative ">
        <div className="w-full h-full bg-[#24337A] -z-40 absolute top-0 left-0"></div>
        <div className="relative w-full min-h-[90vh] md:px-32">
          <div className="relative h-full ">
            <div className="md:flex w-full min-h-[90vh] items-center">
              <div className="w-full md:mr-8 px-4 py-6 md:py-24 md:py-12">
                <div className="font-bold md:text-3xl text-2xl text-white mb-8">
                  <h1 className="text-xl md:text-3xl font-bold mt-3 leading-relaxed">
                    Software apotek terintegrasi <br />
                    tingkatkan efisiensi hingga 90%
                  </h1>
                  <h2 className="font-light text-[14px] md:text-[20px] mt-3 leading-tight text-justify">
                    Jadwalkan konsultasi gratis dengan tim ahli kami{" "}
                    <br className="block md:hidden" />
                    dan dapatkan full akses{" "}
                    <span className="font-bold">FREE TRIAL 14 HARI!</span>
                  </h2>
                </div>
                <div className="z-50">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <input
                      type="text"
                      placeholder="Nama Lengkap"
                      className="text-xs md:text-base font-normal rounded-full px-6 py-3 w-full focus:border-none mb-4"
                      value={reqBody.customer_name}
                      onChange={(e) =>
                        setReqBody({
                          ...reqBody,
                          customer_name: e.target.value,
                        })
                      }
                    />
                    <input
                      type="text"
                      placeholder="Nama Apotek"
                      className="text-xs md:text-base font-normal rounded-full px-6 py-3 w-full focus:border-none mb-4"
                      value={reqBody.pharmacy_name}
                      onChange={(e) =>
                        setReqBody({
                          ...reqBody,
                          pharmacy_name: e.target.value,
                        })
                      }
                    />
                    <input
                      type="text"
                      placeholder="Alamat Email"
                      value={reqBody.customer_email}
                      className="text-xs md:text-base font-normal rounded-full px-6 py-3 w-full focus:border-none mb-4"
                      onChange={(e) =>
                        setReqBody({
                          ...reqBody,
                          customer_email: e.target.value,
                        })
                      }
                    />
                    <input
                      type="text"
                      placeholder="No. WA Aktif"
                      className="text-xs md:text-base font-normal rounded-full px-6 py-3 w-full focus:border-none mb-4"
                      value={reqBody.whatsapp_number}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value)))
                          setReqBody({
                            ...reqBody,
                            whatsapp_number: e.target.value,
                          });
                      }}
                    />
                    <p className="text-white font-bold mb-4">{errorMessage}</p>
                    <div className="md:flex gap-4">
                      <button
                        type="submit"
                        disabled={isPending}
                        className=" flex gap-4 justify-center items-center rounded-full p-3 bg-[#E3E721] cursor-pointer w-full font-bold hover:bg-[#696a12] hover:text-white mb-4 disabled:bg-[#696a12] disabled:text-white"
                      >
                        {isPending ? (
                          <svg
                            aria-hidden="true"
                            class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        ) : null}
                        <p>
                          {isPending ? "Mohon Tunggu" : "Jadwalkan Sekarang"}
                        </p>
                      </button>
                      <button
                        disabled
                        type="button"
                        className="rounded-full p-3 bg-[#9DAEFF] w-full mb-4"
                      >
                        <p>
                          <span className="text-xs block">
                            Diskon berlaku sampai
                          </span>
                          <span className="flex gap-2 items-center justify-center">
                            <BsClock /> <p>{getTime()}</p>
                          </span>
                        </p>
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="w-full relative flex justify-center">
                <img
                  src={PromoTag}
                  className="absolute left-[10%] top-0 md:-top-12 w-1/3 md:w-2/5"
                />
                <img
                  src={DemoImg}
                  className="w-1/2 md:w-1/2 ml-16 md:ml-52 mt-20 md:mt-20 object-contain"
                />
              </div>
            </div>
          </div>
        </div>

        <img
          src={OlinMascot}
          alt=""
          className="hidden lg:block lg:absolute bottom-0 right-24 w-[20vw]"
        />
        <div
          data-aos="fade-up"
          className="absolute -z-20 -bottom-[10%] md:-bottom-[20%] left-0 right-0 mx-auto w-[25vw] w-[280px] xs:w-[350px] sm:w-[500px] h-[280px] xs:h-[350px] sm:h-[500px]"
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 515 257"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="257.5" cy="257.5" r="257.5" fill="#3145A5" />
            <circle cx="257.5" cy="257.5" r="233.5" fill="#4259C7" />
          </svg>
        </div>
        <BubbleBackground bubbleNum={5} />
      </div>
      <img src={Stroke} alt="" className="block w-full" />

      <dialog
        id="confirmation-modal"
        className={`${
          isPending
            ? "min-h-[20vh] md:min-h-[50vh]"
            : "min-h-[50vh] md:min-h-[75vh]"
        }
        backdrop:bg-gray-800 backdrop:opacity-80 rounded-lg p-0 w-2/3 md:w-2/5 overflow-hidden`}
      >
        <DemoDialog dialog={dialog} isPending={isPending} />
      </dialog>
    </>
  );
};

export default DemoBanner;
