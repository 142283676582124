import { GeneratePublicToken } from "../components/utils/GeneratePublicToken";
import axios from "axios";

const TokenAuth = GeneratePublicToken('landing').Authorization;
const TokenAuthID = GeneratePublicToken('landing')["Authorization-ID"];

export const getDeviceList = async () => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ACCOUNT}subscription/public?offset=1&limit=100`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};
