import React from "react";
import LandingPage from "./pages/LandingPage";
import ProductPage from "./pages/ProductPage";
import ArticlePage from "./pages/ArticlePage";
import DetailArticle from "./components/ArticlePage/DetailArticle";
import TentangPage from "./pages/TentangPage";
import PerangkatPage from "./pages/PerangkatPage";
import DemoPage from "./pages/DemoPage";
import FAQPage from "./pages/FAQPage";
import Privacy from "./pages/Privacy";
import Syarat from "./pages/Syarat";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/blog" element={<ArticlePage />} />
        <Route path="/blog-detail/:id" element={<DetailArticle />} />
        <Route path="/tentang" element={<TentangPage />} />
        <Route path="/perangkat" element={<PerangkatPage />} />
        <Route path="/demo" element={<DemoPage />} />
        <Route path="/FAQ" element={<FAQPage />} />
        <Route path="/kebijakan-privasi" element={<Privacy />} />
        <Route path="/syarat-ketentuan" element={<Syarat />} />
      </Routes>
    </div>
  );
}

export default App;
