import { Link } from "react-router-dom";

const ArticleCard = ({
  categoryArr,
  slug,
  title,
  description,
  media,
  hasImage,
  type, //small or big card
}) => {
  const getTitleSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const stripHtml = (text) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = text;
    return tmp.textContent || tmp.innerText || "";
  };

  const CategoryChip = () => {
    return (
      <>
        {categoryArr.slice(0, 3).map((cat, i) => {
          return (
            <p
              className="px-3 rounded-2xl bg-gray-200 py-1 w-fit text-[12px]"
              key={i}
            >
              {cat}
            </p>
          );
        })}
      </>
    );
  };

  return (
    <div
      // data-aos="fade-up"
      className="border border-2 border-slate-200 w-full p-5 my-5 rounded-md"
    >
      {type === "small" ? (
        <>
          <p
            className={`font-bold text-[18px] hover:text-blue-500 hover:underline mb-2`}
          >
            <Link to={`/blog-detail/${slug}`}>{title}</Link>
          </p>
          <CategoryChip />
        </>
      ) : null}

      <div className="w-full flex items-center gap-4">
        <div className="w-full">
          {type === "big" ? (
            <>
              <p
                className={`font-bold text-[28px] hover:text-blue-500 hover:underline mb-2`}
              >
                <Link to={`/blog-detail/${slug}`}>{title}</Link>
              </p>
              <div className="flex gap-2 items-center mb-8">
                <p className="text-[12px]">Kategori </p>
                <CategoryChip />
              </div>
            </>
          ) : null}
          <div
            dangerouslySetInnerHTML={{
              __html: stripHtml(description),
            }}
            className="text-sm my-2 max-h-[100px] overflow-hidden "
          />
        </div>
        <div className={`w-3/4 ${type === "small" ? "hidden" : ""}`}>
          {hasImage ? (
            <div className="w-full my-auto flex items-center">
              <img
                alt="news-image"
                src={media}
                className="h-full w-full object-contain rounded-lg"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
