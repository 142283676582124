import React from "react";

const Accordion = () => {
  return (
    <div className="p-0 md:p-0 px-5 md:px-5">
      <div className="relative w-full overflow-hidden my-5">
        <input
          type="checkbox"
          className="
        peer
        absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
        />
        <div className="bg-white h-12 w-full  flex items-center">
          <p className="text-lg font-semibold text-black">Apa itu OLIN?</p>
        </div>
        <div className="absolute top-3 right-3 text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
          <p>
            OLIN adalah perusahaan teknologi yang menyediakan berbagai solusi
            untuk kebutuhan operasional bisnis apotek.
          </p>
        </div>
      </div>
      <hr />
      <div className="relative w-full overflow-hidden my-5">
        <input
          type="checkbox"
          className="
        peer
        absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
        />
        <div className="bg-white h-12 w-full flex items-center">
          <p className="text-lg font-semibold mr-10 md:mr-0 text-black">
            Bagaimana cara berlangganan dengan OLIN?
          </p>
        </div>
        <div className="absolute top-3 right-3 text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
          <p className="mt-2">
            1. Daftarkan diri Anda melalui aplikasi OLIN atau mulai percakapan
            dengan tim kami di
            <a
              href="https://api.whatsapp.com/send?phone=6281113307870"
              className="text-blue-500"
            >
              {" "}
              Whatsapp
            </a>
            <br className="mt-6" />
            2.Tim kami akan menghubungi & menjawab semua kebutuhan dan
            pertanyaan Anda.
          </p>
        </div>
      </div>
      <hr />
      <div className="relative w-full overflow-hidden my-5">
        <input
          type="checkbox"
          className="
        peer
        absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
        />
        <div className="bg-white h-12 w-full  flex items-center">
          <p className="text-lg font-semibold text-black">
            Berapa biaya berlangganan aplikasi OLIN
          </p>
        </div>
        <div className="absolute top-3 right-3 text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
          <p>
            Dapatkan harga terbaik sistem OLIN senilai IDR 300 k/ bulan untuk
            berlangganan.
          </p>
        </div>
      </div>
      <hr />
      <div className="relative w-full overflow-hidden my-5">
        <input
          type="checkbox"
          className="
        peer
        absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
        />
        <div className="bg-white h-12 w-full  flex items-center">
          <p className="text-lg font-semibold mr-10 md:mr-0 text-black">
            Apakah OLIN menerima pesanan online?
          </p>
        </div>
        <div className="absolute top-3 right-3 text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
          <p>
            Sistem OLIN didedikasikan untuk bisnis penjualan produk farmasi
            seperti apotek, & toko obat.
          </p>
        </div>
      </div>
      <hr />
      <div className="relative w-full overflow-hidden my-5">
        <input
          type="checkbox"
          className="
        peer
        absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
        />
        <div className="bg-white h-12 w-full  flex items-center my-3">
          <p className="text-lg font-semibold mr-10 md:mr-0 text-black">
            Apakah saya akan mendapatkan laporan bulanan?
          </p>
        </div>
        <div className="absolute top-3 right-3 text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
          {/* <p className="mt-4 md:mt-0">OLIN menjual beragam perangkat pelengkap sistem apotek mulai dari tablet pc, monitor, laci kasir, printer, & scanner. Kunjungi halama [Produk] untuk melihat katalog kami.</p> */}
          <p className="mt-4 md:mt-0">
            OLIN menjual beragam perangkat pelengkap sistem apotek mulai dari
            tablet pc, monitor, laci kasir, printer, & scanner.Silahkan hubungi
            tim kami di{" "}
            <a
              href="https://api.whatsapp.com/send?phone=6281113307870"
              className="text-blue-500 "
            >
              Whatsapp
            </a>{" "}
            untuk melihat katalog produk.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
