import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useQuery } from "@tanstack/react-query";

import { getDeviceList } from "../../services/deviceAPI";

const PerangkatList = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const { data } = useQuery({
    queryFn: getDeviceList,
    queryKey: ["device"],
  });

  return (
    <div>
      <div className="container mx-auto mt-20">
        <p className="font-bold text-[30px] mb-5 md:text-[40px] text-center md:text-start md:ml-40">
          Beli Perangkat
        </p>
        <>
          {data && data !== null && data.length !== 0 ? (
            <>
              <div className="mobile block md:hidden">
                <>
                  {data.map((item) => {
                    return (
                      <>
                        <div className="flex flex-col md:flex-row items-center md:items-start  justify-around p-5 ">
                          <div>
                            <img
                              src={item.media}
                              alt=""
                              className="w-full md:w-1/2 md:[100px] mx-0 md:mx-0 md:mt-0 items-center rounded-lg"
                            />
                          </div>

                          <div className="mx-4 md:mx-20">
                            <p className="font-bold text-[25px] md:text-[30px] text-left mt-5 md:mt-10">
                              {item.name}
                            </p>
                            <div className="font-light text-[15px] mt-4 mx-4 block md:hidden">
                              <ul>
                                {item.description.map((desc) => (
                                  <li style={{ listStyleType: "circle" }}>
                                    {desc}
                                  </li>
                                ))}
                              </ul>
                            </div>

                            <div className="">
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-full"
                              >
                                <button className="px-20 my-10 py-2 rounded-xl bg-blue-900 text-white w-full">
                                  Beli sekarang
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              </div>
              <div className="desktop hidden md:block">
                {data && data !== null && data.length > 0
                  ? data.map((item) => {
                      return (
                        <div className="flex flex-row items-center justify-between px-40 my-8">
                          <div className="pr-12 w-full basis-3/5">
                            <img
                              src={item.media}
                              alt=""
                              className="transform duration-200 hover:scale-110 w-full rounded-lg"
                              data-aos="fade-right"
                            />
                          </div>
                          <div
                            className="flex flex-col w-full px-10 rounded-lg my-5 shadow-lg h-full"
                            data-aos="fade-left"
                          >
                            <p className="font-bold text-[30px] my-10">
                              {item.name}
                            </p>
                            <div className="pl-8">
                              <ul>
                                {item.description.map((desc) => (
                                  <>
                                    <li style={{ listStyleType: "circle" }}>
                                      {desc}
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </div>

                            <a
                              href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="w-full px-8 py-10"
                            >
                              <button className="w-full transform duration-200 hover:scale-110 p-2 shadow-lg hover:bg-white hover:text-blue-600 rounded-xl font-bold text-white bg-blue-900">
                                Beli Sekarang
                              </button>
                            </a>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>{" "}
            </>
          ) : null}
        </>
      </div>
    </div>
  );
};

export default PerangkatList;
