import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import TopBanner from "../common/TopBanner";

import TopBannerImgMobile from "../../assets/background/tentangmobile.png";
import TopBannerImg from "../../assets/background/tentang1.png";
import TextImageContainer from "../common/TextImageContainer";

const Banner =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/493e6626b8885c469e6564385f71c0eb9250301f55377f5dac2f8bd2c7e7e5e9.jpg";
const icon1 =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/16745c7b77c9d7af336e9b2d8033599a657b272b6433b771064383fea67e26e4.jpg";
const icon2 =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/c20d35b8fa3563978f90db4003c2f484658012278555a747eab234afefddca37.jpg";
const icon3 =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/742168a662f86d89efc03599e1ef61c5f919918422f1dc4a7e2c0958f4553a14.jpg";
const icon4 =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/44222f701068580cd697def42ff25c3d2956e12ef0cbf3e02a4fd1687bdc047c.jpg";

const Tentang = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <TopBanner
        desktopImg={TopBannerImg}
        mobileImg={TopBannerImgMobile}
        hasButton={true}
        title="Tingkatkan kesehatan Bangsa mulai dari Apotek dengan OLIN"
        subtitle={
          <>
            Bisnis apotek baik bukan hanya tentang menjual obat, <br />
            namun juga membantu kesehatan masyarakat
          </>
        }
      />
      <div className="w-full mx-auto">
        <p
          className="hidden md:block font-bold p-24 px-0 md:px-40 text-[17px]"
          data-aos="zoom-in-up"
        >
          Peran apotek di sebuah negara bukan hanya menjual obat, namun juga
          membantu menunjang kesehatan masyarakat. Maka dari itu, dengan kami
          mengembangkan OLIN kami percaya operasional apotek dapat dibuat lebih
          efisien, maka akan banyak sekali manfaatnya.
          <p className="mt-10">
            Misi dari OLIN adalah untuk membantu apotek berkembang & tidak
            tertinggal oleh pesatnya perkembangan industri sekarang.
          </p>
        </p>
      </div>

      <TextImageContainer
        align="right"
        content={
          <div className="flex flex-col">
            <p className="font-bold text-[25px] md:text-[40px] text-left mt-5 md:mt-10">
              Langkah pertama dalam perkembangan apotek untuk Indonesia yang
              lebih baik
            </p>
            <p className="font-light text-[15px] lg:font-medium lg:text-[20px] mt-4">
              Dengan digitalisasi sistem operasional apotek, OLIN ingin
              memberikan kemudahan untuk para pemilik apotek. Tidak hanya proses
              operasional yang dapat menjadi lebih mudah & otomatis,
              digitalisasi dapat menyambungkan apotek-apotek dengan jaringan
              <span className="italic"> telemedicine </span>
              yang sedang berkembang pesat.
            </p>
          </div>
        }
        imgSrc={icon1}
        aosImg="fade-up"
        aosText="fade-right"
      />
      <TextImageContainer
        align="left"
        content={
          <div className="flex flex-col">
            <p className="font-bold text-[25px] md:text-[40px] text-left mt-5 md:mt-10">
              Beralih menjadi apotek yang pintar & efisien
            </p>
            <p className="font-light text-[15px] lg:font-medium lg:text-[20px] mt-4">
              Banyaknya proses dalam mengelola apotek sering kali membebankan
              para pemilik apotek, sampai tidak ada lagi waktu untuk
              mengembangkan bisnisnya. OLIN memahami keadaan tersebut &
              berusahan untuk memberikan fitur-fitur yang dapat mengakomodasi
              semua proses operasional apotek. Fitur yang dikembangkan OLIN akan
              berfokus untuk membuat proses-proses tersebut lebih efisien &
              otomatis, tidak lagi dikerjakan secara manual.
            </p>
          </div>
        }
        imgSrc={icon2}
        aosImg="fade-down"
        aosText="fade-up"
      />
      <TextImageContainer
        align="right"
        content={
          <div className="flex flex-col">
            <p className="font-bold text-[25px] md:text-[40px] text-left mt-5 md:mt-10">
              Perkembangan yang tetap aman & terpercaya
            </p>
            <p className="font-light text-[15px] lg:font-medium lg:text-[20px] mt-4">
              OLIN tidak lupa bahwa industri kesehatan memerlukan pengawasan
              lebih agar aman bagi masyarakat dan apotek, maka dari itu kami
              bekerjasama dengan pakar terpercaya di bidang farmasi. Tidak hanya
              itu saja, OLIN juga bekerjasama dengan mitra farmasi, distribusi,
              & teknologi yang terpercaya dan mengikuti aturan pemerintah yang
              berlaku untuk menyediakan layanan pendukung apotek
            </p>
          </div>
        }
        imgSrc={icon3}
        aosImg="fade-up"
        aosText="fade-right"
      />
      <TextImageContainer
        align="left"
        content={
          <div className="flex flex-col">
            <p className="font-bold text-[25px] md:text-[40px] text-left mt-5 md:mt-10">
              Karena prioritas kami adalah untuk memastikan kenyamanan dan
              kualitas terbaik untuk seluruh mitra dan pelanggan
            </p>
          </div>
        }
        imgSrc={icon4}
        aosImg="fade-up"
        aosText="fade-right"
      />
      <div className="my-4 mb-20 mx-0 lg:mx-36" data-aos="fade-up">
        <img src={Banner} alt="" />
      </div>
    </>
  );
};

export default Tentang;
