import { GeneratePublicToken } from "../components/utils/GeneratePublicToken";
import axios from "axios";

export const setWebsiteLead = async (body) => {
  const TokenAuth = GeneratePublicToken("backstage").Authorization;
  const TokenAuthID = GeneratePublicToken("backstage")["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_OSCAR}oscar-bs/leads/create-public`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};
