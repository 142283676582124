import React from "react";

import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import FloatButton from "../components/utils/FloatButton";
import Seo from "../components/Seo";
import Landing from "../components/LandingPage/Landing";
import ContactBanner from "../components/common/ContactBanner";

const LandingPage = () => {
  return (
    <div className="w-full">
      <Seo
        title={"OLIN"}
        description={
          "One-stop-solution software apotek dari OLIN membantu pemilik apotek untuk melakukan transformasi digital secara efesien"
        }
        name={"OLIN"}
        canonLink={"https://olin.co.id"}
      />

      <div className="sticky top-0 bg-white z-30 shadow-lg">
        <Navbar />
      </div>
      <div className="relative">
        <div className="fixed bottom-6 right-3 z-30">
          <FloatButton />
        </div>
      </div>

      {/* Page Content */}
      <Landing />
      <ContactBanner />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
