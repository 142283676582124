import React from "react";
import BlueBg from "../../assets/background/bghero.png";
import Stroke from "../../assets/background/Struk.svg";
import { useNavigate } from "react-router-dom";

const TopBanner = ({
  desktopImg,
  mobileImg,
  hasButton,
  title,
  subtitle,
  url,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      <div className="relative w-full h-[100vh]">
        <div className="relative h-full">
          <img src={BlueBg} className="w-full -z-20 absolute h-full" alt="" />
          <div className="md:flex w-full h-[90vh]">
            <div className="w-full">
              <div className="w-full md:flex items-center justify-end md:px-[100px] h-[90vh] justify-center">
                <div className="relative h-[70%]">
                  <img
                    alt=""
                    src={mobileImg}
                    className="absolute right-0 block md:hidden h-full object-contain "
                  />
                </div>
                <div className="md:relative w-full text-white font-bold md:text-3xl md:mr-8  px-4 py-6 md:py-24 md:py-12 text-2xl">
                  <h1 className="text-white text-[20px] md:text-[32px] font-bold mt-3 leading-relaxed">
                    {title}
                  </h1>
                  <h2 className="text-white font-light text-[14px] md:text-[20px] mt-3 leading-tight">
                    {subtitle}
                  </h2>

                  {hasButton && !url ? (
                    <div>
                      <button
                        className="w-max text-sm md:text-lg md:block text-[#24337A] px-12 py-2 bg-white font-bold rounded-xl mt-4 md:mt-10 hover:bg-blue-400 hover:text-white"
                        onClick={() => navigate("/demo")}
                      >
                        Jadwalkan Demo
                      </button>
                    </div>
                  ) : hasButton && url ? (
                    <a
                      href="https://api.whatsapp.com/send?phone=6281113307870"
                      className="bg-white text-[#312e81] rounded-md hover:bg-blue-500 hover:text-white py-4 px-12 text-sm"
                    >
                      Hubungi Kami
                    </a>
                  ) : null}
                </div>
                <img
                  src={desktopImg}
                  alt=""
                  className="w-full object-contain h-[70vh] w-[50vw] pt-12 hidden md:block"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={Stroke} alt="" className="hidden lg:block w-full" />
    </div>
  );
};

export default TopBanner;
