import React from "react";
import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";

const root = document.getElementById("root");
const helmetContext = {};

const queryClient = new QueryClient();

const app = (
  <React.StrictMode>
    <HelmetProvider
      context={helmetContext}
      data-react-helmet="true"
      data-rh="true"
    >
      <Router>
        <QueryClientProvider client={queryClient}>
          <ScrollTop />
          <App />
        </QueryClientProvider>
      </Router>
    </HelmetProvider>
  </React.StrictMode>
);

// ReactDOM.hydrate(app, root);
// root.render(app);
// ReactDOM.hydrate(app, document.getElementById("root"))
if (root.hasChildNodes()) {
  hydrate(app, root);
} else {
  render(app, root);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
