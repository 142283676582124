import React from "react";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import Seo from "../components/Seo";
import Demo from "../components/Demo/Demo";

const DemoPage = () => {
  return (
    <div>
      <Seo
        title={"Demo OLIN"}
        description={
          "Jadwalkan Jadwalkan konsultasi gratis dengan tim ahli kami dan dapatkan full akses FREE TRIAL 14 HARI!"
        }
        name={"OLIN"}
        canonLink={"https://olin.co.id"}
      />
      <div className="sticky top-0 bg-white z-30 shadow-lg">
        <Navbar />
      </div>

      <Demo />
      <Footer />
    </div>
  );
};

export default DemoPage;
