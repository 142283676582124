import React from "react";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import TopBanner from "../components/common/TopBanner";
import TermsSection from "../components/Terms/TermsSection";
import Seo from "../components/Seo";

import TopBannerImgMobile from "../assets/background/privacymobile.png";
import TopBannerImg from "../assets/background/privacy.png";

const Syarat = () => {
  return (
    <div>
      <Seo
        title={"Syarat & Ketentuan OLIN"}
        description={"Temukan syarat dan ketentuan dari OLIN"}
        name={"OLIN"}
        canonLink={"https://olin.co.id"}
      />
      <div className="sticky top-0 bg-white z-30 shadow-lg">
        <Navbar />
      </div>
      <TopBanner
        desktopImg={TopBannerImg}
        mobileImg={TopBannerImgMobile}
        hasButton={false}
        title="Syarat & Ketentuan"
        subtitle={<></>}
      />
      <TermsSection />
      <Footer />
    </div>
  );
};

export default Syarat;
