import { sha256 } from "js-sha256";
import moment from "moment";

export function GeneratePublicToken(type) {
  let id = moment(Date.now()).utc().add(2, "m").unix();
  const secretKey = `${
    type === "landing"
      ? process.env.REACT_APP_SECRET_KEY
      : process.env.REACT_APP_SECRET_KEY_BACKSTAGE
  }`;

  const authCompareByte = sha256(`${secretKey}${id}`);
  return { Authorization: authCompareByte, "Authorization-ID": id };
}
