import React from "react";
import Bg from "../../assets/background/bgsec3.png";
import { AiOutlineCheck } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Img =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/522b5061e5c7bfc883614c3d64c8bca93e6c3ebc1ca1fad7855326216ad6cbe8.jpg";

const PriceBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="relative h-[100vh]">
      <img src={Bg} alt="" className="absolute -z-10 h-[100vh] w-full" />
      <div className="flex flex-col md:flex-row items-center justify-center h-[100vh] my-auto">
        <div className="bg-white flex flex-col md:flex-row items-center mx-10 rounded-xl block md:hidden max-h-full my-auto justify-center">
          <p className="font-bold text-xl md:text-2xl text-center mt-4">
            Harga
          </p>
          <img
            src={Img}
            alt=""
            className="w-[220px] md:w-[600px] mt-4 items-center"
          />
          <div className="items-start p-5">
            <p id="harga-section" className="font-bold text-[16px] text-start">
              OLIN - Sistem Managemen Farmasi
            </p>

            <p className="font-bold text-[12px] mt-4 text-start">
              Rp.300k/Bulan/Outlet
            </p>
            <div className="flex flex-row gap-2 items-center mt-7">
              <AiOutlineCheck className="w-[23px] h-[25px] text-green-500" />
              <p className="ml-3 text-[14px]">Dashboard & laporan penjualan</p>
            </div>
            <div className="flex flex-row gap-2 items-center mt-3">
              <AiOutlineCheck className="w-[23px] h-[25px] text-green-500" />
              <p className="ml-3 text-[14px]">Sistem POS</p>
            </div>
            <div className="flex flex-row gap-2 items-center mt-3">
              <AiOutlineCheck className="w-[23px] h-[25px] text-green-500" />
              <p className="ml-3 text-[14px]">Sistem Manajemen Inventory</p>
            </div>
            <div className="flex flex-row gap-2 items-center mt-3">
              <AiOutlineCheck className="w-[23px] h-[25px] text-green-500" />
              <p className="ml-3 text-[14px]">Akses ke integrasi platform</p>
            </div>
            <div className="flex flex-row gap-2 items-center mt-3">
              <AiOutlineCheck className="w-[23px] h-[25px] text-green-500" />
              <p className="ml-3 text-[14px]">Akses ke pinjaman dana</p>
            </div>
          </div>
          <button
            className="bg-[#24337A] my-4 text-white items-center font-bold rounded-full px-10 py-2 hover:bg-white hover:text-blue-600"
            onClick={() => navigate("/demo")}
          >
            Jadwalkan Demo
          </button>
        </div>

        <div className="bg-white my-auto flex flex-row  hidden md:flex rounded-xl items-center p-10 mx-24">
          <div className="flex flex-col">
            <p className="font-bold text-[30px]">
              OLIN - Sistem Manajemen Farmasi
            </p>
            <p className="font-bold text-[30px]">Rp.300k/Bulan/Outlet</p>
            <div className="flex flex-row gap-5 items-center mt-7">
              <AiOutlineCheck className="w-[35px] h-full text-green-500" />
              <p className="ml-3 text-[14px] font-semibold">
                Dashboard & laporan penjualan
              </p>
            </div>
            <div className="flex flex-row gap-5 items-center mt-3">
              <AiOutlineCheck className="w-[35px] h-full text-green-500" />
              <p className="ml-3 text-[14px] font-semibold">Sistem POS</p>
            </div>
            <div className="flex flex-row gap-5 items-center mt-3">
              <AiOutlineCheck className="w-[35px] h-full text-green-500" />
              <p className="ml-3 text-[14px] font-semibold">
                Sistem Manajemen Inventory
              </p>
            </div>
            <div className="flex flex-row gap-5 items-center mt-3">
              <AiOutlineCheck className="w-[35px] h-full text-green-500" />
              <p className="ml-3 text-[14px] font-semibold">
                Akses ke integrasi platform
              </p>
            </div>
            <div className="flex flex-row gap-5 items-center mt-3">
              <AiOutlineCheck className="w-[35px] h-full text-green-500" />
              <p className="ml-3 text-[14px] font-semibold">
                Akses ke pinjaman dana
              </p>
            </div>
            <div>
              <button
                className="bg-[#24337A] my-4 text-white items-center font-bold rounded-full px-16 py-2 hover:bg-white hover:text-blue-600"
                onClick={() => navigate("/demo")}
              >
                Jadwalkan Demo
              </button>
            </div>
          </div>
          <div>
            <img src={Img} alt="" className="w-[1000px] pl-20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceBanner;
