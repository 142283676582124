import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const TextImageContainer = ({ align, content, imgSrc, aosImg, aosText }) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="flex flex-col lg:flex-row items-start justify-around w-full my-8 px-0 lg:px-36 items-center">
      <div
        className="w-full flex justify-center"
        data-aos={align === "left" ? aosImg : aosText}
      >
        {align === "left" ? (
          <img src={imgSrc} className="w-full px-0 py-4 object-contain" alt="" />
        ) : (
          <div className="px-8">{content}</div>
        )}
      </div>
      <div
        className={`w-full flex justify-center ${
          align === "left" ? "" : "order-first lg:order-last"
        }`}
        data-aos={align === "left" ? aosText : aosImg}
      >
        {align === "left" ? (
          <div className="px-8">{content}</div>
        ) : (
          <img src={imgSrc} className="w-full px-0 py-4 object-contain" alt="" />
        )}
      </div>
    </div>
  );
};

export default TextImageContainer;
