import { useEffect, useState } from "react";

const BubbleBackground = ({ bubbleNum }) => {
  const [numberArrHor, setNumberArrHor] = useState([]);
  const [numberArrVer, setNumberArrVer] = useState([]);

  const generateNumber = (type) => {
    return type === "ver"
      ? Math.floor(Math.random() * 80 + 12)
      : Math.floor(Math.random() * 100 + 12);
  };

  useEffect(() => {
    var temp = [];
    [...Array(bubbleNum)].map((item) => temp.push(generateNumber("hor")));
    setNumberArrHor(temp);

    var temp = [];
    [...Array(bubbleNum)].map((item) => temp.push(generateNumber("ver")));
    setNumberArrVer(temp);
  }, []);

  return (
    <>
      {[...Array(bubbleNum)].map((item, i) => {
        return (
          <div
            className={`absolute -z-20`}
            style={{
              left: i % 2 === 0 ? numberArrHor[i] : null + "vw",
              right: i % 2 === 0 ? null : numberArrHor[i] + "vw",
              top: numberArrVer[i] + "vh",
            }}
            data-aos={i % 3 === 0 ? "" : "fade-up"}
          >
            <svg
              width={i % 3 === 0 ? "32" : i % 2 === 0 ? "65" : "29"}
              height={i % 3 === 0 ? "32" : i % 2 === 0 ? "65" : "29"}
              viewBox="0 0 58 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="29" cy="29" r="29" fill="#4259C7" />
            </svg>
          </div>
        );
      })}
    </>
  );
};

export default BubbleBackground;
