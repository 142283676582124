import React from 'react';
import Wa from '../../assets/icons/wa.png';

const FloatButton = () => {
  return (
    <div>
      <div className="text-center z-30">
        <span className="inline-flex items-center shadow-lg rounded-full p-2 bg-white text-black group transition-all duration-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-none" role="alert" tabindex="0">
          <img src={Wa} alt="" />

          <span className="whitespace-nowrap inline-block group-hover:max-w-screen-2xl group-focus:max-w-screen-2xl max-w-0 scale-80 group-hover:scale-100 overflow-hidden transition-all duration-500 group-hover:px-2 group-focus:px-2">
            <a href="https://api.whatsapp.com/send?phone=6281113307870" className="text-blue-500 ">
              Hubungi Kami
            </a>
          </span>
        </span>
      </div>
    </div>
  );
};

export default FloatButton;
