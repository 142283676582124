import React from "react";
import Articles from "../components/ArticlePage/Articles";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import Seo from "../components/Seo";

const ArticlePage = () => {
  return (
    <div>
      <Seo
        title={"Artikel OLIN"}
        description={
          "Pelajari tips & trik serta dapatkan update terbaru seputar bisnis apotek"
        }
        name={"OLIN"}
        canonLink={"https://olin.co.id"}
      />
      <div className="sticky top-0 bg-white z-30 shadow-lg">
        <Navbar />
      </div>

        <Articles />
        <Footer />
    </div>
  );
};

export default ArticlePage;
