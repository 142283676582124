import React from "react";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import Tentang from "../components/TentangPage/Tentang";
import FloatButton from "../components/utils/FloatButton";
import Seo from "../components/Seo";
import ContactBanner from "../components/common/ContactBanner";

const TentangPage = () => {
  return (
    <div>
      <Seo
        title={"Tentang OLIN"}
        description={
          "One-stop-solution software apotek dari OLIN membantu pemilik apotek untuk melakukan transformasi digital secara efesien"
        }
        name={"OLIN"}
        canonLink={"https://olin.co.id"}
      />
      <div className="sticky top-0 bg-white z-30 shadow-lg">
        <Navbar />
      </div>
      <div className="relative">
        <div className="fixed bottom-6 right-3 z-30">
          <FloatButton />
        </div>
      </div>

      <Tentang />
      <ContactBanner />

      <Footer />
    </div>
  );
};

export default TentangPage;
