import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { PopupButton } from "react-calendly";
import { useNavigate } from "react-router-dom";

const Person =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/94556801a6eda5a57421735b5fdebd45b819e01287fd3e1bd82797ec4cb36706.png";

const BannerLanding = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="bg-[#24337A]">
      <div className="w-full">
        <div className="flex flex-col md:flex-row items-center px-0">
          <div className="relative w-full md:w-[700px]">
            <img
              src={Person}
              alt=""
              className=" pt-10 pb-5 md:py-20 sm:block w-full px-8 relative -left-1/4 md:left-0"
              data-aos="fade-up"
            />
          </div>
          <div className="flex-col items-center" data-aos="fade-right">
            <p className="text-white font-bold text-[28px] lg:text[48px] mx-4 md:mx-20">
              Bergabung bersama OLIN & dapatkan kemudahan dalam bisnis apotek
              Anda.
            </p>
            <button
              className="text-base mx-4 text-[#24337A] font-bold md:mx-20 py-2 hidden md:block bg-white rounded-md px-[50px] my-4 hover:bg-blue-400 hover:text-white"
              onClick={() => navigate("/demo")}
            >
              Jadwalkan Demo
            </button>
            <button
              className="text-base mx-4 text-[#24337A] font-bold md:mx-20 py-2 block md:hidden bg-white rounded-2xl px-[40px] my-7 hover:bg-blue-400 hover:text-white"
              onClick={() => navigate("/demo")}
            >
              Jadwalkan Demo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerLanding;
