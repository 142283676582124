import { GeneratePublicToken } from "../components/utils/GeneratePublicToken";
import axios from "axios";

export const getTermsAndCondition = async (type) => {
  const TokenAuth = GeneratePublicToken('landing').Authorization;
  const TokenAuthID = GeneratePublicToken('landing')["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_BACKSTAGE}/marketing-termscondition?type=${type}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};
