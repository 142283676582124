import React from "react";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PrivacySection from "../components/Privacy/PrivacySection";
import Seo from "../components/Seo";
import TopBanner from "../components/common/TopBanner";

import TopBannerImg from "../assets/background/privacy.png";
import TopBannerImgMobile from "../assets/background/privacymobile.png";

const Privacy = () => {
  return (
    <div>
      <Seo
        title={"Kebijakan Privasi OLIN"}
        description={"Temukan kebijakan privasi dari OLIN"}
        name={"OLIN"}
        canonLink={"https://olin.co.id"}
      />
      <div className="sticky top-0 bg-white z-30 shadow-lg">
        <Navbar />
      </div>

      <TopBanner
        desktopImg={TopBannerImg}
        mobileImg={TopBannerImgMobile}
        hasButton={true}
        title="Privasi dan datamu aman dengan kami"
        subtitle={<></>}
      />
      <PrivacySection />

      <Footer />
    </div>
  );
};

export default Privacy;
