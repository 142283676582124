import TopBanner from "../common/TopBanner";
import PerangkatList from "./PerangkatList";

import TopBannerImg from "../../assets/background/perangkat1.png";
import TopBannerImgMobile from "../../assets/background/perangkatmobile.png";

const Perangkat = () => {
  return (
    <>
      <TopBanner
        desktopImg={TopBannerImg}
        mobileImg={TopBannerImgMobile}
        hasButton={false}
        title=" Permudah sistem manajemen farmasi Anda dengan Perangkat yang
        OLIN sediakan"
        subtitle={<></>}
      />

      <PerangkatList />
    </>
  );
};

export default Perangkat