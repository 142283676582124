import React from "react";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import FloatButton from "../components/utils/FloatButton";
import Seo from "../components/Seo";
import ContactBanner from "../components/common/ContactBanner";
import FAQ from "../components/FAQPage/FAQ";

const FAQPage = () => {
  return (
    <div className="w-full">
      <Seo
        title={"FAQ OLIN"}
        description={
          "Pelajari lebih lanjut tentang software apotek dari OLIN, hubungi kami untuk informasi lebih detail. Kami siap membantu anda."
        }
        name={"OLIN"}
        canonLink={"https://olin.co.id"}
      />
      <div className="sticky top-0 bg-white z-30 shadow-lg">
        <Navbar />
      </div>
      <div className="relative">
        <div className="fixed bottom-6 right-3 z-30">
          <FloatButton />
        </div>
      </div>

      <FAQ />
      <ContactBanner />

      <Footer />
    </div>
  );
};

export default FAQPage;
