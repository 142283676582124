import { useRef } from "react";
import { useInView } from "react-intersection-observer";

import TopBanner from "../common/TopBanner";
import TextImageContainer from "../common/TextImageContainer";
import Person2group from "../../assets/background/person2group.png";
import Person3group from "../../assets/background/person3group.png";
import Person4group from "../../assets/background/person4group.png";
import Person5 from "../../assets/background/person5fix.png";
import Person6 from "../../assets/background/person6.png";
import Person7 from "../../assets/background/person7.png";
import PriceBanner from "./PriceBanner";
import Stroke from "../../assets/background/Struk.svg";
import Footer from "../common/Footer";

const Bg =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/130790f1d1f68fe30adef7f71cbc71de375906264e60698684096fe3c519f852.png";
const TopBannerImg =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/9d7f74e9ee102360216839e2979e5c659995b531c404d16bbe9a5e0724552577.png";
const personConsult =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/92a070116d49a803d846573e043c4bf792131bf4276dc20c391a478defc97de7.jpg";
const personApothecary =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/93043cfb35238ed694c4250e15c589eb26d1534e16086aa9326f156bd0d8ab35.jpg";
const Person2 =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/21da2a4b0a8f95c6c7ff762acaa7d323da28395ef977bc14ea69fc91b58b683e.jpg";
const Person3 =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/b5b4a8ebc07d514fc6415632e0ba252f5beea375ad1400451f46c1c71f624516.jpg";
const Person4 =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/86fa3624d430673bd17f6b9f17eed47a3dd57558cd67e3a6dc25656aa91f9b52.jpg";
const Person2ext =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/5626af16dffab52c76ef645e9c7d5272717d8980c95eef4c885b02dd53ee5393.png";
const Person3ext =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/fcfab0d91dc37aec8fb862c8ad3a62db9d1571357c2e8eadee9a7469c34c2371.png";
const Person4ext =
  "https://staging-olin.sgp1.digitaloceanspaces.com/landingpage/44163b3d8fbacaba1dc576ba911d137a94361d25c08c324cda48e79d11f99b83.png";

const Product = () => {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0.5,
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0.5,
  });

  const { ref: thirdContainer, inView: thirdInView } = useInView({
    threshold: 0.5,
  });
  const { ref: fourContainer, inView: fourInView } = useInView({
    threshold: 0.5,
  });
  const { ref: fiveContainer, inView: fiveInView } = useInView({
    threshold: 0.5,
  });
  const { ref: sixContainer, inView: sixInView } = useInView({
    threshold: 0.5,
  });

  const getImage = () => {
    if (firstInView) return Person2group;
    if (secondInView || (secondInView && firstInView)) return Person3group;
    if (thirdInView || (thirdInView && secondInView)) return Person4group;
    if (fourInView || (fourInView && thirdInView)) return Person5;
    if (fiveInView || (fiveInView && fourInView) || (fiveInView && thirdInView))
      return Person6;
    if (sixInView || (fiveInView && sixInView)) return Person7;
    else return Person2group;
  };

  return (
    <div className="h-[100vh] overflow-y-scroll snap-none md:snap-y md:snap-mandatory scrollbar-hide">
      <div className="snap-proximity snap-start">
        <TopBanner
          desktopImg={TopBannerImg}
          mobileImg={TopBannerImg}
          hasButton={true}
          title="Tingkatkan kesehatan Bangsa mulai dari Apotek dengan OLIN"
          subtitle={
            <>
              Bisnis apotek baik bukan hanya tentang menjual obat, <br />
              namun juga membantu kesehatan masyarakat
            </>
          }
        />
      </div>
      <div className="snap-proximity snap-start h-screen flex items-center">
        <TextImageContainer
          align="left"
          content={
            <div className="flex flex-col">
              <p className="font-bold text-[25px] md:text-[40px] text-left mt-4 md:mt-10">
                Saatnya untuk berpindah menjadi Apotek Digital
              </p>
              <p className="font-light md:font-medium text-[20px] md:text-[15px] mt-4">
                Banyaknya produk kesehatan dan kebutuhan konsumen yang selalu
                meningkat membuat pengelolaan sebuah apotek sering kali terasa
                berat dengan sistem yang masih manual. Dengan Aplikasi manajemen
                farmasi OLIN, proses apotek Anda dapat didigitalisasi & menjadi
                lebih optimal.
              </p>
            </div>
          }
          imgSrc={personConsult}
          aosImg=""
          aosText=""
        />
      </div>
      <div className="snap-proximity snap-start">
        <div>
          <div className="mobile">
            <div className="flex flex-col items-start mt-16 sm:hidden">
              <div className="relative">
                <img src={Person2} alt="" className="w-[250px]" />
                <div className="static">
                  <img
                    src={Person2ext}
                    alt=""
                    className="w-[190px]  absolute left-[160px] top-20"
                  />
                </div>
              </div>
              <div className="flex flex-col px-3 mt-5">
                <p className="font-bold text-[20px]">
                  Atur Semua inventori Anda dengan mudah!
                </p>
                <p className="mt-3">
                  Sistem pencatatan inventori terintegrasi dengan mudah dan
                  cepat, Olin dapat terhubung dengan semua platform penjualan
                  Anda yang selalu terupdate setiap saat.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start mt-16 md:hidden">
              <div className="relative">
                <img src={Person3} alt="" className="w-[250px]" />
                <div className="static">
                  <img
                    src={Person3ext}
                    alt=""
                    className="w-[100px]  absolute left-[193px] top-20"
                  />
                </div>
              </div>
              <div className="flex flex-col px-3 mt-5">
                <p className="font-bold text-[20px]">
                  Atur Semua inventori Anda dengan mudah!
                </p>
                <p className="mt-3">
                  Sistem pencatatan inventori terintegrasi dengan mudah dan
                  cepat, Olin dapat terhubung dengan semua platform penjualan
                  Anda yang selalu terupdate setiap saat.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start my-16 md:hidden">
              <div className="relative">
                <img src={Person4} alt="" className="w-[250px]" />
                <div className="static">
                  <img
                    src={Person4ext}
                    alt=""
                    className="w-[100px]  absolute left-[195px] top-20"
                  />
                </div>
              </div>
              <div className="flex flex-col px-3 mt-5">
                <p className="font-bold text-[20px]">
                  Atur Semua inventori Anda dengan mudah!
                </p>
                <p className="mt-3">
                  Sistem pencatatan inventori terintegrasi dengan mudah dan
                  cepat, Olin dapat terhubung dengan semua platform penjualan
                  Anda yang selalu terupdate setiap saat.
                </p>
              </div>
            </div>
          </div>

          <img
            src={getImage()}
            ref={imageRef}
            className={`snap-none absolute top-4 bottom-0 left-36 h-screen w-1/3 object-contain p-14 xl:p-16 ${
              !firstInView &&
              !secondInView &&
              !thirdInView &&
              !fourInView &&
              !fiveInView &&
              !sixInView
                ? "hidden"
                : ""
            }`}
            alt="a"
          />

          <div className="w-full dekstop hidden md:flex flex-wrap justify-between relative mt-20">
            <div
              className="snap-always snap-center h-screen w-full flex items-center justify-end content-center md:mr-36"
              ref={firstContainer}
            >
              <div className="basis-1/3">.</div>
              <div className="basis-2/3 float-right pl-40">
                <h1 className=" font-bold text-[50px]">
                  Satu aplikasi untuk seluruh kebutuhan Apotek Anda
                </h1>
              </div>
            </div>
            <div
              className="snap-always snap-center h-screen w-full flex items-center justify-end flex-wrap content-center md:mr-36"
              ref={secondContainer}
            >
              <div className="basis-1/3">.</div>
              <div className="basis-2/3 float-right pl-40">
                <h1 className="font-bold text-[50px]">
                  Atur semua inventori Anda dengan mudah!
                </h1>
                <p className="font-medium text-[20px]">
                  Sistem pencatatan inventori yang terintegrasi dengan semua
                  platform penjualan Anda membuat proses update inventori mudah,
                  cepat, & terupdate setiap saat.
                </p>
              </div>
            </div>
            <div
              className="snap-always snap-center h-screen w-full flex items-center justify-end flex-wrap content-center md:mr-36"
              ref={thirdContainer}
            >
              <div className="basis-1/3">.</div>
              <div className="basis-2/3 float-right pl-40">
                <h1 className="font-bold text-[50px]">
                  Tingkatkan jumlah penjualan melalui offline & online
                </h1>
                <p className="font-medium text-[20px]">
                  Baik penjualan di toko maupun melalui apotek online di
                  telemedicine dapat dikonfirmasi dalam satu layar.
                </p>
              </div>
            </div>
            <div
              className="snap-always snap-center h-screen w-full flex items-center justify-end flex-wrap content-center md:mr-36"
              ref={fiveContainer}
            >
              <div className="basis-1/3">.</div>
              <div className="basis-2/3 float-right pl-40">
                <h1 className="font-bold text-[50px]">
                  Tidak lagi memiliki produk out-of-stock
                </h1>
                <p className="font-medium text-[20px]">
                  Sistem pintar pengingat restock dapat menotifikasi Anda jika
                  ada produk yang akan habis atau kadarluarsa. OLIN juga
                  terintegrasi dengan sistem pembelian dari distributor secara
                  langsung.
                </p>
              </div>
            </div>
            <div
              className="snap-always snap-center h-screen w-full flex items-center justify-end flex-wrap content-center md:mr-36"
              ref={sixContainer}
            >
              <div className="basis-1/3">.</div>
              <div className="basis-2/3 float-right pl-40">
                <h1 className="font-bold text-[50px]">
                  Monitor performa bisnis bukan hal yang sulit lagi!
                </h1>
                <p className="font-medium text-[20px]">
                  Dengan sistem business inteligence OLIN yang tersentralisasi,
                  Anda dapat memonitor performa bisnis apotek Anda kapanpun &
                  dimanapun melalui satu layar
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="snap-proximity snap-start">
        <PriceBanner />
      </div>

      <div className="snap-proximity snap-start flex flex-col md:flex-row mt-12 md:mt-32 items-start  md:p-0 px-0 md:px-40 py-0 md:py-16 gap-0 md:gap-10">
        <TextImageContainer
          align="right"
          content={
            <div className="flex flex-col">
              <p className="font-bold text-[25px] md:text-[40px] text-left mt-4 md:mt-10">
                Berikan kemudahan untuk bisnis Apotek anda & juga pelanggan
              </p>
              <p className="font-light md:font-medium text-[20px] md:text-[15px] mt-4">
                Semua kebutuhan proses Apotek melalui satu aplikasi
              </p>
            </div>
          }
          imgSrc={personApothecary}
          aosImg=""
          aosText=""
        />
      </div>
      <div className="snap-proximity snap-start">
        <div className="w-full mx-auto bg-[#24337A]">
          <div className="bg-[#24337A] px-5 md:px-24">
            <div className="relative md:hidden">
              <img src={Bg} alt="" className="p-2 rounded-full" />
              <div className="text-white flex flex-col">
                <p className="font-bold text-[30px] md:text-[50px] text-white pt-[20px]">
                  Ingin mengetahui lebih lanjut?
                </p>
                <p>
                  Kami siap menjawab dan memandu Anda meningkatkan usaha apotek
                  Anda dengan OLIN. Hubungi kami sekarang!
                </p>
                <button className="text-blue-800 font-bold bg-white py-[12px] mt-[50px] rounded-[30px] px-16 hover:bg-blue-500 hover:text-white">
                  <a href="https://api.whatsapp.com/send?phone=6281113307870">
                    Hubungi Kami
                  </a>
                </button>
              </div>
            </div>
            <div className="hidden md:flex w-full pt-10 flex flex-row items-center justify-around">
              <div className="text-white">
                <p className="font-bold text-[50px]">
                  Ingin mengetahui <br /> lebih lanjut?
                </p>
                <p className="mt-5">
                  Kami siap menjawab dan memandu Anda meningkatkan usaha apotek{" "}
                  <br /> Anda dengan OLIN. Hubungi kami sekarang!
                </p>
                <button className="text-blue-800 font-bold bg-white py-[12px] mt-20 rounded-[30px] px-16 hover:text-white hover:bg-blue-700">
                  <a href="https://api.whatsapp.com/send?phone=6281113307870">
                    Hubungi Kami
                  </a>
                </button>
              </div>
              <div>
                <img src={Bg} alt="" className="rounded-full" />
              </div>
            </div>
          </div>
          <img src={Stroke} alt="" className="mt-5 w-full" />
        </div>
      </div>
      <div className="snap-proximity snap-end">
        <Footer />
      </div>
    </div>
  );
};

export default Product;
