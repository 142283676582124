import { MdOutlineCancel } from "react-icons/md";
import BubbleBackground from "./BubbleBackground";
import Congratulation from "../../assets/background/congratulation.svg";

const DemoDialog = ({ dialog, isPending }) => {
  return (
    <div
      className={`${
        isPending
          ? "min-h-[20vh] md:min-h-[50vh]"
          : "min-h-[50vh] md:min-h-[75vh]"
      } shadow-md relative w-full h-full overflow-hidden bg-gradient-to-r from-[#F5ED13] from-2.32% via-[#7AC57A] via-37.09% via-[#00ACED] via-72.39% to-[#001489] to-103.56% p-1`}
    >
      <div className="p-8">
        <div className="flex flex-col items-center w-[calc(100%-8px)] h-[calc(100%-8px)] z-10 bg-[#24337A] absolute rounded-lg top-0 bottom-0 my-auto left-0 right-0 mx-auto">
          <div className="absolute bottom-0 left-0 right-0 mx-auto w-[40vw] md:w-[30vw]">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 515 257"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="257.5" cy="257.5" r="257.5" fill="#3145A5" />
              <circle cx="257.5" cy="257.5" r="233.5" fill="#4259C7" />
            </svg>
          </div>
          <BubbleBackground bubbleNum={10} />
          <img
            src={Congratulation}
            className="z-20 absolute bottom-0 md:-bottom-[10%] lg:-bottom-[20%] right-0 left-0 mx-auto"
          />
        </div>
        {isPending ? (
          <div className="min-h-[20vh] md:min-h-[50vh] flex flex-col items-center justify-center relative z-30 text-white text-center">
            <p className="text-xl md:text-3xl font-bold mb-8">Mohon menunggu</p>
            <p className="text-xs md:text-lg">
              Permohonan anda sedang diproses
            </p>
          </div>
        ) : (
          <div className="min-h-[50vh] md:min-h-[75vh] relative z-30 text-white flex justify-between">
            <div>
              <p className="text-xl md:text-3xl font-bold mb-4">Terima Kasih</p>
              <p className="text-xs md:text-lg">
                Sebentar lagi, akan ada tim kami yang
                <br /> segera menghubungi Anda!
              </p>
            </div>
            <div>
              <MdOutlineCancel
                className="hover:text-red-500 text-gray-300 cursor-pointer w-[20px] h-[20px]"
                onClick={() => dialog.close()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DemoDialog;
